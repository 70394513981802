<template>
  <v-autocomplete
    :items="cores"
    :rules="coreRules"
    :label="i18n(label)"
    :loading="loading"
    :dense="outlined"
    :outlined="outlined"
    :readonly="readonly"
    :autofocus="autofocus"
    :clearable="clearable"
    :filter="() => true"
    :search-input.sync="search"
    :prepend-inner-icon="icon"
    :hide-details="hideDetails"
    :no-data-text="'filter.sync'.translate()"
    v-model="core"
    @keyup.enter="$emit('on-enter', $event)"
  />
</template>

<script>
import { requests } from '@/plugins/Amplify'
import { coreRules } from '@/rules'
const onlyNumber = (str) => isNaN(str) ? null : Number(str)
const onlyString = (str) => String(str || '').trim()

export default {
  props: {
    icon: { default: 'mdi-star' },
    value: {},
    label: { default: 'field.core' },
    outlined: { default: false },
    readonly: { default: false },
    autofocus: { default: false },
    hideDetails: { default: false },
    clearable: { default: false }
  },

  data () {
    return {
      cores: [],
      search: null,
      loading: false,
      timeout: null,
      core: onlyNumber(this.value),
      coreRules
    }
  },

  methods: {
    clearTimeout () {
      clearTimeout(this.timeout)
      this.timeout = null
    },

    loadCores (params) {
      this.loading = true
      this.timeout = setTimeout(async () => {
        try {
          const data = await requests.getCores(params)
          this.cores = data.map(({ id, nucleo, regiao }) => ({ value: id, text: `${nucleo} - ${regiao}` }))
        } catch (error) {
          console.error(error)
        }

        this.loading = false
      }, 275)
    }
  },

  mounted () {
    const value = onlyNumber(this.value)
    if (value) this.loadCores({ id: value })
  },

  watch: {
    core () {
      const core = onlyNumber(this.core)
      if (core !== onlyNumber(this.value)) this.$emit('input', core)
    },

    value () {
      const value = onlyNumber(this.value)
      if (value === onlyNumber(this.core)) return

      this.core = value
      this.loadCores({ id: value })
    },

    search () {
      this.clearTimeout()

      const nome = onlyString(this.search)
      if (!nome || this.cores.find(({ text }) => text === nome)) {
        this.loading = false
        return
      }

      this.loadCores({ nome })
    }
  }
}
</script>
